import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const CountDownHome = dynamic(() => import('./CountDownHome'), { ssr: false });
interface TopPicksProps {
  categoryData: any;
  // data: React.ReactNode[];
}

const TopPicks: React.FC<TopPicksProps> = ({ categoryData = {} }) => {
  return (
    <>
      <section className={styles.toppickssection}>
        <div className={styles.toppicksinner}>
          <div className="container-1250">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.toppicksheading}>
                  <h2>{categoryData?.title}</h2>
                  <p>{categoryData?.subtitle}</p>
                </div>
              </div>
            </div>

            <div className={styles.toppickscontent}>
              <div className="wdRow">
                {categoryData?.items?.map((category: any, index: number) => (
                  <div
                    className="wdCol-lg-2 wdCol-md-3 wdCol-sm-4 wdCol-6"
                    key={index}
                  >
                    <Link
                      href={category.href}
                      className={styles.toppickshecard}
                    >
                      <div className={styles.toppicksimg}>
                        <Image
                          src={category.imgSrc}
                          alt={category.altText}
                          title={category.categoryName}
                          width={192}
                          height={163}
                          loading="lazy"
                          quality={100}
                        />
                      </div>
                      <div className={styles.toppickstext}>
                        <p className={styles.categoryname}>
                          {category.categoryName}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.storesection}>
        <div className={styles['storeinner']}>
          <div className="container-fluid">
            <div className={styles.storeimg}>
              <Link href="/offline-furniture-store">
                <div className={styles['heading-online']}>
                  <CountDownHome />
                </div>
                <Image
                  alt=""
                  src="/images/home-new1/store-mid-banner.jpg?v=175"
                  width={1826}
                  height={204}
                  loading="lazy"
                  quality={100}
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopPicks;
